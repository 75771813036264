var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TableForm',{attrs:{"title":"Клієнти","headers":_vm.headers,"items":_vm.clients,"items-meta":_vm.clientsMeta,"load-function":_vm.loadClients,"payload-preset":_vm.payload,"with-search":"","search-fields":['user_id', 'first_name', 'last_name'],"date-filter-fields":['last_payment_date']},scopedSlots:_vm._u([{key:"item.user_id",fn:function({ item }){return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
        name: 'ClientCard',
        params: {
          id: _vm.currentCompanyId.toString(),
          clientId: item.user_id.toString(),
        },
      }}},[_vm._v(" "+_vm._s(item.user_id)+" ")])]}},{key:"item.full_name",fn:function({ item }){return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
        name: 'ClientCard',
        params: {
          id: _vm.currentCompanyId.toString(),
          clientId: item.user_id.toString(),
        },
      }}},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")])]}},{key:"item.birthday",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("getShortDate")(item.birthday)))]}},{key:"item.is_active_client",fn:function({ item }){return [_c('CircleIndicator',{attrs:{"is-on":item.is_active_client}})]}},{key:"item.payment_count",fn:function({ item }){return [_vm._v(_vm._s(item.payment_count.toLocaleString()))]}},{key:"item.payment_amount",fn:function({ item }){return [_vm._v(_vm._s(item.payment_amount.toLocaleString()))]}},{key:"item.last_payment_date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("getShortDate")(item.last_payment_date))+" "),_c('sup',[_vm._v(_vm._s(_vm._f("getShortTime")(item.last_payment_date)))])]}},{key:"item.actions",fn:function({ item }){return [_c('TableActions',{attrs:{"no-edit-button":"","no-delete-button":""},on:{"view":function($event){return _vm.navigateToClientCard(item)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('BonusPayroll',{attrs:{"user-id":item.user_id},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-tooltip',{attrs:{"open-on-focus":false,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: ttOn, attrs: ttAttrs }){return [_c('button',_vm._g(_vm._b({staticClass:"v-icon v-icon--link mdi mr-1"},'button',ttAttrs,false),ttOn),[_c('img',{attrs:{"src":require("@/assets/images/cashback.svg"),"alt":"Cashback icon","width":"16","height":"16"}})])]}}],null,true)},[_c('span',[_vm._v("Нарахувати бонуси")])])],1)]}}],null,true)})]},proxy:true}],null,true)})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }